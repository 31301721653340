<template>
    <div id="card-content">
        <!-- Card title -->
        <!-- Always display title even if there is none! -->
        <v-card-title class="pt-2 mr-8">
            <label
                class="card-title cursor-inherit overflow-ellipsis"
                v-html="title"
                :title="title"
                :style="textStyle"
            />
        </v-card-title>

        <!-- Card subtitle -->
        <v-card-subtitle v-if="showSubtitle" class="text--primary pb-2">
            <label
                class="card-subtitle cursor-inherit"
                v-html="subtitle"
                :style="textStyle"
            />
        </v-card-subtitle>

        <!-- Description -->
        <v-card-text v-if="showDescription" class="text--primary pb-1">
            <card-description :text="description" :style="textStyle" />
        </v-card-text>
    </div>
</template>

<script>
import CardDescription from "@/components/cards/CardDescription.vue";

export default {
    name: "CardContent",
    props: {
        title: String,
        subtitle: String,
        description: String,
    },
    components: {
        CardDescription,
    },
    computed: {
        cardSettings() {
            return this.$store.getters["directory/getDirectory"].settingsCard;
        },
        showSubtitle() {
            return this.cardSettings.subtitle && this.subtitle;
        },
        showDescription() {
            return this.cardSettings.description && this.description;
        },
        hasFooter() {
            return (
                this.cardSettings.showRedeem ||
                this.cardSettings.showLocation ||
                this.cardSettings.showInformation
            );
        },
        textStyle() {
            return { color: this.cardSettings.textColor };
        },
    },
};
</script>

<style>
</style>