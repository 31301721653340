<template>
	<v-row class="mt-0">
		<v-overlay :value="campaigns == null" color="#ffffff" absolute />

		<v-col
			v-if="settingsCategory.filters || settingsCategory.breadcrumbs"
			cols="12"
			class="pb-0 pt-0"
		>
			<v-row>
				<!-- Category breadcrumbs -->
				<v-col
					cols="10"
					md="7"
					class="pt-2 pb-0"
					:style="breadcrumbsStyle"
				>
					<h2 v-if="search">
						<label id="search-label">
							{{ $t("content.search-result") }} "<strong>{{
								search
							}}</strong
							>"
						</label>

						<v-icon
							class="remove-search"
							@click="removeSearch"
							style="color: inherit"
						>
							mdi-close
						</v-icon>
					</h2>

					<category-breadcrumbs
						v-else-if="settingsCategory.breadcrumbs"
					/>
				</v-col>

				<!-- Filters -->
				<v-col
					v-if="settingsCategory.filters"
					cols="2"
					md="5"
					class="pt-2 pb-1"
				>
					<filters @filter="setFilters" />
				</v-col>
			</v-row>
		</v-col>

		<!-- Categories -->
		<v-col
			v-if="showCategories"
			cols="12"
			:md="settingsCategory.leftCategories ? '3' : '12'"
			class="pb-0"
		>
			<categories />
		</v-col>

		<!-- Cards -->
		<v-col cols="12" :md="cardColumns" class="pb-0">
			<cards :cards="activeCampaigns" />
		</v-col>
	</v-row>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import categoryHelpers from "@/mixins/categoryHelpers.js";
import filterHelpers from "@/mixins/filterHelpers.js";

import CategoryBreadcrumbs from "@/components/content/CategoryBreadcrumbs.vue";
import Categories from "@/components/content/Categories.vue";
import Cards from "@/components/content/Cards.vue";
import Filters from "@/components/content/Filters.vue";

export default {
	name: "DirectoryContent",
	components: {
		CategoryBreadcrumbs,
		Categories,
		Cards,
		Filters,
	},
	data() {
		return {
			activeCampaigns: null,

			search: null,
			filters: [],
		};
	},
	mixins: [categoryHelpers, filterHelpers],
	computed: {
		directory() {
			return this.$store.getters["directory/getDirectory"];
		},
		settingsCategory() {
			return this.directory.settingsCategory;
		},
		settingsAuthentication() {
			return this.directory.settingsAuthentication;
		},
		settingsCard() {
			return this.directory.settingsCard;
		},
		categories() {
			return this.$store.getters["directory/getCategories"];
		},
		currentCategory() {
			return this.$store.getters["directory/getCurrentCategory"];
		},
		campaigns() {
			return this.$store.getters["cards/getCards"];
		},
		cardColumns() {
			if (this.search) return 12;

			return this.settingsCategory.leftCategories ? 9 : 12;
		},
		showCategories() {
			// Hide categories if searched
			if (this.search) return false;

			// Hide categories if left categories disabled and no categories
			return (
				this.settingsCategory.leftCategories ||
				this.categories.length > 0
			);
		},
		breadcrumbsStyle() {
			return { color: this.settingsCategory.breadcrumbsColor };
		},
		coordinates() {
			return this.$store.getters["user/getCoordinates"];
		},
	},
	mounted() {
		EventBus.$on("search", (search) => {
			this.search = search;

			this.filterCampaigns();
		});

		this.$store.dispatch("cards/retrieveCampaigns").catch((err) => {
			console.log(err);
		});
	},
	methods: {
		filterCampaigns() {
			if (this.campaigns == null) return;

			// Filter campaigns (search & category)
			let campaigns = this.filter(
				this.campaigns,
				this.categories,
				this.search,
				this.getCategory(this.categories, this.currentCategory)
			);

			// Remove invisible campaigns
			// campaigns = campaigns.filter((e) => e.visible === true);

			// Secondary filter campaigns (recommended, favorite, expired)
			if (this.filters.length > 0) {
				// Also sorts campaigns based on distance if close-by filter was selected
				campaigns = this.secondaryFilter(campaigns);
			}

			// Remove expired coupons
			if (this.settingsCard.hideExpired) {
				campaigns = this.removeExpiredCoupons(campaigns);
			}

			// Remove validated coupons
			if (this.settingsCard.hideValidated) {
				campaigns = this.removeValidatedCoupons(campaigns);
			}

			// Remove loyalty if authentication not required
			if (!this.settingsAuthentication.requireAuthentication) {
				campaigns = this.removeLoyaltyCards(campaigns);
			}

			this.activeCampaigns = campaigns;
			this.$nextTick(() => {
				this.$redrawVueMasonry();
			});
		},
		secondaryFilter(campaigns) {
			let tmp = [];

			for (let i = 0; i < campaigns.length; ++i) {
				let include = true;

				if (this.filters.includes("recommended"))
					include &= campaigns[i].recommended;

				if (this.filters.includes("favorites"))
					include &= campaigns[i].favorite;

				if (this.filters.includes("close-by")) {
					// Check if location close by
					let locations = campaigns[i].campaign.locations;

					let closeBy = false;
					for (let j = 0; j < locations.length; ++j) {
						if (locations[j].distance > 10) continue;

						closeBy = true;
						break;
					}

					include &= closeBy;
				}

				if (this.filters.includes("claimed"))
					include &= campaigns[i].status === "claimed";

				if (this.filters.includes("validated"))
					include &= campaigns[i].status === "validated";

				if (include) tmp.push(campaigns[i]);
			}

			// Sort cards based on shortest location
			if (this.filters.includes("close-by")) {
				tmp = this.sortByDistance(tmp);
			}

			return tmp;
		},
		removeExpiredCoupons(campaigns) {
			return campaigns.filter((e) => {
				return !(
					Date.parse(e.campaign.expirationDate) -
						Date.parse(new Date()) <
					0
				);
			});
		},
		removeValidatedCoupons(campaigns) {
			// Do not remove validated coupons if filter is enabled
			if (this.filters.includes("validated")) return campaigns;

			return campaigns.filter((e) => e.status !== "validated");
		},
		removeLoyaltyCards(campaigns) {
			return campaigns.filter((e) => e.type !== "loyalty");
		},
		sortByDistance(campaigns) {
			return [
				...campaigns.sort(function (a, b) {
					return a.closest.distance - b.closest.distance;
				}),
			];
		},
		setFilters(filters) {
			this.filters = filters;
		},
		removeSearch() {
			EventBus.$emit("remove-search");
		},
	},
	watch: {
		campaigns: {
			handler() {
				if (localStorage.getItem("current-category")) {
					// Set current category stored in localStorage
					this.$store.commit(
						"directory/SET_CURRENT_CATEGORY",
						parseInt(localStorage["current-category"])
					);

					localStorage.removeItem("current-category");
				} else {
					this.filterCampaigns();
				}
			},
		},
		filters: {
			handler() {
				this.filterCampaigns();
			},
		},
		currentCategory: {
			handler() {
				this.filterCampaigns();
			},
		},
		coordinates: {
			handler() {
				this.$store.dispatch("cards/retrieveCampaigns").catch((err) => {
					console.log(err);
				});
			},
		},
	},
};
</script>

<style scoped>
.remove-search {
	margin-bottom: 2px !important;
	cursor: pointer;
}
</style>