<template>
	<v-card
		:id="`card-id-${card.id}`"
		class="card-tile d-flex flex-column"
		:class="{ 'card-transform': cardSettings.hover }"
		:style="cardStyle"
		@click.native="cardClickable ? cardClicked() : null"
	>
		<div class="card-top">
			<!-- Card image -->
			<v-img
				class="card-tile-image border-top-inherit"
				:src="card.customImage ? card.customImage : card.campaign.image"
				@load="redrawMasonry"
			/>

			<!-- Category name -->
			<v-chip
				v-if="cardSettings.showCategory && category"
				class="card-image-chip card-category-chip"
				:text-color="category.textColor"
				:color="category.backgroundColor"
			>
				{{ category.name }}
			</v-chip>

			<!-- Recommended -->
			<v-chip
				v-if="card.recommended"
				class="card-image-chip"
				:style="recommendedChipStyle"
				:text-color="cardSettings.recommendedTextColor"
				:color="cardSettings.recommendedBackgroundColor"
			>
				{{ cardSettings.recommendedLabel }}
			</v-chip>
		</div>

		<!-- Card content -->
		<div class="card-bottom d-flex flex-column flex-grow-1 overflow-hidden">
			<!-- Favorite -->
			<v-icon
				v-if="authenticationSettings.requireAuthentication"
				:style="textStyle"
				class="favorite no-background-hover"
				v-bind:class="{ 'favorite-active': card.favorite }"
				@mousedown.stop
				@click.prevent.stop="
					authenticated ? favorite() : openAuthenticationDialog()
				"
			>
				{{ card.favorite ? "mdi-heart" : "mdi-heart-outline" }}
			</v-icon>

			<!-- Card content (title, subtitle, description, ...) -->
			<card-content
				:title="removeTags(card.campaign[cardSettings.title])"
				:subtitle="removeTags(card.campaign[cardSettings.subtitle])"
				:description="
					removeTags(card.campaign[cardSettings.description])
				"
			/>

			<!-- Expiration date: Do not add expiration date to <card-content /> as it breaks the expiration being pushed to the bottom of the card -->
			<v-card-text
				v-if="showExpiration"
				:style="textStyle"
				class="pt-0 mt-auto"
				:class="{ 'pb-0': hasFooter, 'pb-1': !hasFooter }"
			>
				<small>
					{{ `${$t("card.valid-until")}: ${formatDate(card)} ` }}
				</small>
			</v-card-text>

			<!-- Controls (redeem, location, ...) -->
			<v-card-actions v-if="hasFooter" class="pl-4 pr-4 pt-0">
				<card-actions :card="card" :loading="loading" />
			</v-card-actions>
		</div>
	</v-card>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { redeem } from "@/services/redeem.js";

import categoryHelpers from "@/mixins/categoryHelpers.js";
import layoutHelpers from "@/mixins/layoutHelpers.js";

import CardContent from "@/components/cards/CardContent.vue";
import CardActions from "@/components/cards/CardActions.vue";

import moment from "moment";

export default {
	name: "CardTile",
	props: {
		card: Object,
	},
	components: {
		CardContent,
		CardActions,
	},
	data() {
		return {
			loading: false,
		};
	},
	mixins: [categoryHelpers, layoutHelpers],
	mounted() {
		EventBus.$on("reset-loading", () => {
			this.loading = false;
		});
	},
	computed: {
		authenticationSettings() {
			return this.$store.getters["directory/getDirectory"]
				.settingsAuthentication;
		},
		cardSettings() {
			return this.$store.getters["directory/getDirectory"].settingsCard;
		},
		authenticated() {
			return this.$store.getters["auth/authenticated"];
		},
		category() {
			// getCategory in categoryHelpers mixin
			return this.getCategory(
				this.$store.getters["directory/getCategories"],
				this.card.categoryId
			);
		},
		cardClickable() {
			return this.cardSettings.cardClickable && !this.loadingState;
		},
		showExpiration() {
			// Don't show expiration date for loyalty cards or flexible expiration date
			return (
				this.cardSettings.showExpirationDate &&
				this.card.campaign["expirationDate"]
			);
		},
		hasFooter() {
			return (
				this.cardSettings.showRedeem ||
				this.cardSettings.showLocation ||
				this.cardSettings.showInformation
			);
		},
		loadingState() {
			return this.$store.getters["cards/loading"];
		},
		textStyle() {
			return { color: this.cardSettings.textColor };
		},
		directoryDateFormat() {
			return this.$store.getters["directory/directoryDateFormat"];
		},
	},
	methods: {
		// Card images are dynamic which gives scaling issues for masonry
		// Redraw all cards when images are loaded
		redrawMasonry() {
			var self = this;

			setTimeout(function () {
				self.$redrawVueMasonry();
			}, 100);
		},
		cardClicked() {
			this.loading = true;

			redeem(this.card);
		},
		favorite() {
			this.card.favorite = !this.card.favorite;

			let data = {
				cardId: this.card.id,
				favorite: this.card.favorite,
			};

			this.$store.dispatch("cards/favorite", data);
		},
		openAuthenticationDialog() {
			EventBus.$emit("open-authentication-dialog");
		},
		removeTags(html) {
			if (!html) return null;

			return html.replace(/<\/?[^>]+(>|$)/g, "");
		},
		formatDate(card) {
			return moment(card.campaign["expirationDate"]).format(
				this.directoryDateFormat + " HH:mm:ss"
			);
		},
	},
};
</script>

<style scoped>
.card-bottom {
	position: relative;
}

.border-top-inherit {
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}

.card-top {
	/* Make sure the recommended chip has a reference point */
	position: relative;
}

.card-transform {
	transition: transform 0.2s;
}

.card-transform:hover {
	transform: scale(1.04);
}

.card-image-chip {
	position: absolute;
	margin: 8px;
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 500;
}

.card-image-chip.card-category-chip {
	top: 0;
	right: 0;
}

.favorite {
	position: absolute !important;
	right: 10px;
	top: 10px;
}

/* Classes for Custom CSS (don't remove!) */
.card-tile {
}
.card-tile-image {
}
.card-tile-title {
}
.card-tile-subtitle {
}
.card-tile-content {
}
</style>